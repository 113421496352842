<template>
  <section class="form-group" @click.self="checkShow=false">
    <form class="container" ref="form" @click.self="checkShow=false" @submit.prevent>
      <h4>{{$t('SubmitEvent')}}</h4>
      <input type="hidden" name="type" value="0">
      <div class="list-name" ref='title'> {{$t('Title')}} <span>*</span></div>
      <input type="text" name="title" :placeholder="$t('Title')" v-model="title" autocomplete="off">
      <div class="list-name" ref="description">{{$t('Description')}} <span>*</span></div>
      <textarea name="description" :placeholder="$t('Description')" v-model="description" ref="des" @input="textareaHeight" autocomplete="off"></textarea>
      <!-- logo -->
      <div class="list-name" ref='img'>{{$t('Image')}} ({{$t('ImageSize')}} : 800*450 px) <span>*</span></div>
      <div class="upload-pic" id="fileContainer" :style="'backgroundImage:url('+ (img?'https://oss-us-cdn.maiziqianbao.net/'+img:require('@/assets/img/icon/photo.png'))+')'">
        <div id="file"></div>
        <input type="hidden" name="img" v-model="img">
      </div>
      <div class="list-name"> {{$t('URL')}} </div>
      <input type="text" name="url" :placeholder="$t('URL')" v-model="url" autocomplete="off">
      <div class="list-name" ref='eventType'>{{$t('EventType')}} <span>*</span></div>
      <select :class="{selected:eventType}" name="eventType" v-model="eventType">
        <option :value="item.ID" v-for="item in eventTags" :key="item.ID">{{item.title}}</option>
        <option value="4" selected>{{$t('Other')}}</option>
      </select>
      <div class="list-name" ref="country"> {{$t('Country')}} </div>
      <select :class="{selected:country}" name="country" v-model="country" v-if="countryList">
        <option value='' disabled selected>{{$t('SelectCountry')}}</option>
        <option v-for="(item,key) in countryList" :value="key" :key="key">{{item}}</option>
      </select>
      <div class="list-name" ref='eventDate'>{{$t('Start')}}</div>
      <input type="datetime-local" name="eventDate" v-model="eventDate" autocomplete="off">
      <div class="list-name"> {{$t('End')}} </div>
      <input type="datetime-local" name="eventEndDate" v-model="eventEndDate" autocomplete="off">
      <!-- 提交申请 -->
      <a href="javascript:;" class="submit-btn" @click="submitProject">{{$t('Submit')}}</a>
    </form>
  </section>
</template>
<script>
import OssUploader from '@/assets/js/oss.js'
export default {
  data(){
    return {
      countryList:null,
      img:"",
      country:"",
      title:"",
      description:"",
      url:"",
      eventType:4,
      eventDate:"",
      eventEndDate:"",
    }
  },
  created(){
    this.getList();
  },
  mounted(){
    // 上传图片
    if(this.$refs.form){
      OssUploader.initUploader('bscEvent').then(img=>{
        this.img = img;
      })
    }
  },
  methods:{
    getList(){
      this.axios.get(this.event_oss_link+'country/countryList.json').then(res=>{
        this.countryList = res.data
      })
    },
    // textarea字符限制
    limitByteSize() {
      this.shortByte = this.webUtil.computeByteSize(this.introduction);
      if (this.shortByte > 256) {
        return false;
      }
      return true
    },
    // textarea高度自适应
    textareaHeight(e) {
      var textarea = e.target
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    validate() {
      if (!this.title.trim()) {
        this.$toast(this.$t('EnterTitle'));
        window.scrollTo(0, this.$refs.title.offsetTop - 74);
        return false;
      }
      if (!this.description) {
        this.$toast(this.$t('EnterDescription'));
        window.scrollTo(0, this.$refs.description.offsetTop - 74);
        return false;
      }
      if (!this.img) {
        this.$toast(this.$t('EnterImage'));
        window.scrollTo(0, this.$refs.img.offsetTop - 74);
        return false;
      }
      if (!this.eventType) {
        this.$toast(this.$t('EnterEventType'));
        window.scrollTo(0, this.$refs.eventType.offsetTop - 74);
        return false;
      }
      return true;
    },
    // 提交表单
    submitProject() {
      if (!this.validate()) {
        return false;
      }

      this.$loading(1);
      var formData = new FormData(this.$refs.form);

      // 去除空文件元素
      try {
        for (var pair of formData.entries()) {
          if (pair[1] instanceof File && pair[1].name == '' && pair[1].size == 0)
            formData.delete(pair[0]);
        }
      } catch (e) {}

      this.axios.post(this.domain + "submitBscEvent",formData).then(res=>{
        this.$loading(0);
        if (res.data.success) {
          this.$toast(this.$t('SubmitSuccess')).then(()=>{
            this.$router.push('/event');
          })
        } else {
          this.$toast(res.data.message,3000);
        }
      }).catch(err=>{
        this.$loading(0);
        console.log(err);
        this.$toast(this.$t('Error'));
      })
    },
  }
}
</script>
